






  import { Component, Prop, Vue } from "vue-property-decorator";

@Component({})
  export default class DataQualityPercentageIndicator extends Vue {
  @Prop({ type: Number, default: 0 }) percentage!: number;
  @Prop({ type: Number, default: 5 }) warningDeviation!: number;
  @Prop({ type: Number, default: 10 }) criticalDeviation!: number;

  get percentageColor(): string {
    if (this.percentage > this.criticalDeviation!) {
      return "redText";
    }
    if (this.percentage > this.warningDeviation!) {
      return "orangeText";
    }
    return "greenText";
  }
  }
