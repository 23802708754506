


















import { Component, Prop, PropSync, Vue } from "vue-property-decorator";

@Component
export default class DataAvailabilityModalFooter extends Vue {
  @PropSync("isOpen", { type: Boolean, default: false }) dialog!: boolean;
  @Prop({ type: String, default: "" }) displayedMessage!: string;
  @Prop({ type: Boolean, default: "" }) isDisabled!: boolean;
}
