













































































































































































































import { Vue, Component, PropSync, Prop, Watch } from "vue-property-decorator";
import DataQualityIndicator from "@/components/dataQuality/DataQualityIndicator.vue";
import { DataQualityTagConfiguration } from "@/types/dataQuality/dataQualityTagConfiguration";

@Component({
  components: {
    DataQualityIndicator,
  },
})
export default class DataAvailabilityModalConfigureTags extends Vue {
  @PropSync("selectedTags", { type: Array, default: [] }) tagsToConfigure!: DataQualityTagConfiguration[];
  @PropSync("commonConfiguration", { type: Object, default: [] }) commonConfig!: DataQualityTagConfiguration;
  @PropSync("switch", { type: Boolean, default: false }) applySameConfig!: boolean;
  @PropSync("hasGlobalError", { type: Boolean, default: false }) syncHasGlobalError!: boolean;
  @Prop({ type: Boolean, default: () => [] }) loading!: boolean[];

  items = [{ state: "hour (s)" }, { state: "day (s)" }, { state: "month (s)" }];

  // @Watchers
  @Watch("tagsToConfigure", { deep: true, immediate: true })
  onTagsToConfigureChanged(): void {
    this.syncHasGlobalError = this.tagsHasError;
  }
  @Watch("commonConfig", { deep: true, immediate: true })
  onCommonConfigChanged(): void {
    this.syncHasGlobalError = this.commonConfigHasError;
  }

  @Watch("applySameConfig", { immediate: true })
  onApplySameConfigChanged(): void {
    this.syncHasGlobalError = this.applySameConfig ? this.commonConfigHasError : this.tagsHasError;
  }

  //  @Getters
  get tagsHasError(): boolean {
    return this.tagsToConfigure.some(tag => this.hasError(tag));
  }

  get commonConfigHasError(): boolean {
    return this.hasError(this.commonConfig);
  }

  //  @Methods
  updateConfigurationTag($value: number | null, ref: string | null, data: DataQualityTagConfiguration): void {
    if ($value && ref && Number($value) === 0) {
      $value = 1;
      (this.$refs[ref] as any)[0].internalValue = $value;
    }
    this.$emit("updateConfigurationTag", data);
  }
  onTagBlur(ref: string | null, data: DataQualityTagConfiguration): void {
    if (ref && Number((this.$refs[ref] as any)[0].internalValue) === 0) {
      const $value = 1;
      (this.$refs[ref] as any)[0].internalValue = $value;
    }
    this.$emit("updateConfigurationTag", data);
  }

  hasError(data: DataQualityTagConfiguration): boolean {
    return this.convertToSeconds(data.observeTimeSpan, data.observeTimeSpanUnit) >= this.convertToSeconds(data.criticalTimeSpan, data.criticalTimeSpanUnit);
  }

  onInput($value: number, ref: string): void {
    if ($value && ref && Number($value) === 0) {
      $value = 1;
      (this.$refs[ref] as any).internalValue = $value;
    }
  }

  onBlur($value: number, ref: string): void {
    if (ref && Number((this.$refs[ref] as any).internalValue) === 0) {
      $value = 1;
      (this.$refs[ref] as any).internalValue = $value;
    }
  }

  numericFilter(event: any): void {
    event = event ? event : window.event;
    const expect = event.target.value.toString() + event.key.toString();
    return !/^\d+$/.test(expect) ? event.preventDefault() : false;
  }

  convertToSeconds(number: string, unit: string): number {
    if (unit === "year (s)") {
      return Number(number) * 31536000;
    }
    if (unit === "month (s)") {
      return Number(number) * 2592000;
    }
    if (unit === "day (s)") {
      return Number(number) * 86400;
    }
    if (unit === "hour (s)") {
      return Number(number) * 3600;
    }
    if (unit === "minute (s)") {
      return Number(number) * 60;
    }
    return Number(number);
  }
}
