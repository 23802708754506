import { render, staticRenderFns } from "./DataAvailabilityModalConfigureTags.vue?vue&type=template&id=752d9970&scoped=true&"
import script from "./DataAvailabilityModalConfigureTags.vue?vue&type=script&lang=ts&"
export * from "./DataAvailabilityModalConfigureTags.vue?vue&type=script&lang=ts&"
import style0 from "./DataAvailabilityModalConfigureTags.vue?vue&type=style&index=0&id=752d9970&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "752d9970",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VSelect } from 'vuetify/lib/components/VSelect';
import { VSkeletonLoader } from 'vuetify/lib/components/VSkeletonLoader';
import { VSwitch } from 'vuetify/lib/components/VSwitch';
import { VTextField } from 'vuetify/lib/components/VTextField';
installComponents(component, {VSelect,VSkeletonLoader,VSwitch,VTextField})
