













































































import { Component, Prop, Vue } from "vue-property-decorator";
import DataQualityTableRow from "@/components/dataQuality/dataQualityTable/DataQualityTableRow.vue";
import { DataQualityHeader, DataQualityRow } from "@/types/dataQuality/dataQualityTable";
import outageHubConnection from "../../../utilities/signalr/signalr-outagehub-service";
import UserModule from "@/store/clients/User.module";
import { getModule } from "vuex-module-decorators";
import store from "@/store";

const User = getModule(UserModule, store);

@Component({
  components: {
    DataQualityTableRow,
  },
})
export default class DataQualityTable extends Vue {
  @Prop({ type: Boolean, default: false }) showOverallStatus!: Boolean;
  @Prop({ type: Boolean, default: false }) loading!: Boolean;
  @Prop({ type: Array, default: () => [] }) headers!: DataQualityHeader[];
  @Prop({ type: Array, default: () => [] }) rows!: DataQualityRow[];
  @Prop({ type: Array, default: () => [] }) links!: string[] | null;

  limitPerPage = 10;

  get isLessThanLimitPerPage(): boolean {
    return this.rows.length <= this.limitPerPage;
  }

  created() {
    if (outageHubConnection.state === "Disconnected") {
      outageHubConnection
        .start()
        .then(() => {
          outageHubConnection.invoke("JoinGroup", `company-${User.userCompanyId}`);
        })
        .catch(err => console.error(err.toString()));
    } else {
      console.log("Connection is already started or in the process of starting.");
    }

    outageHubConnection.on("OutageUpdated", (outage, vesselInfo) => {
      this.$emit("outage-updated", vesselInfo.vesselName, outage.featureName, outage);
    });
  }

  beforeDestroy() {
    outageHubConnection
      .invoke("LeaveGroup", `company-${User.userCompanyId}`)
      .then(() => {
        outageHubConnection.off("OutageUpdated");
        outageHubConnection.stop();
      })
      .catch(err => console.error(err.toString()));
  }

  get indicatorsCount(): number {
    return this.headers.length;
  }

  get skeletonStructure(): object {
    return { "table-row": `table-cell@6` };
  }

  customSort(items: any, sortBy: any, isDesc: any): [] {
    //  do nothing when the sorting is reset
    if (!sortBy[0]) return items;

    //  sort as strings
    const result = items.sort((a: any, b: any) => {
      if (isDesc[0]) return b[sortBy].value < a[sortBy].value ? -1 : 1;
      return a[sortBy].value < b[sortBy].value ? -1 : 1;
    });
    return result;
  }
}
