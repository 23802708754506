import { render, staticRenderFns } from "./DataAvailabilityModalFooter.vue?vue&type=template&id=eb771c4e&scoped=true&"
import script from "./DataAvailabilityModalFooter.vue?vue&type=script&lang=ts&"
export * from "./DataAvailabilityModalFooter.vue?vue&type=script&lang=ts&"
import style0 from "./DataAvailabilityModalFooter.vue?vue&type=style&index=0&id=eb771c4e&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "eb771c4e",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VRow } from 'vuetify/lib/components/VGrid';
installComponents(component, {VBtn,VCardActions,VCol,VRow})
