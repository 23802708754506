















































































































import { Component, Prop, Vue } from "vue-property-decorator";
import store from "@/store";
//  utilities
import dateHelper from "Utilities/date-helper";
import moment from "moment";

import { getModule } from "vuex-module-decorators";
import DataQualityModule from "@/store/clients/DataQuality.module";

const DataQuality = getModule(DataQualityModule, store);

@Component({})
export default class DataQualityIndicator extends Vue {
  @Prop({ type: Number, default: 0 }) showOverallStatus!: Number;
  @Prop({ type: Boolean, default: false }) withText!: boolean;
  @Prop({ type: String, default: "" }) columnName!: string;
  @Prop({ type: String, default: "" }) link?: string;
  @Prop({ type: String, default: null }) lastDataTimestamp?: string | null;
  @Prop({ type: String, default: null }) outageStartTime?: string | null;

  //  @Getters
  get isOverallStatusColumn(): boolean {
    return this.columnName === "Overall Status";
  }

  get status(): string {
    switch (this.showOverallStatus) {
      case 1:
        return "Available";
      case 2:
        return "Observe";
      case 3:
        return "Alert";
      default:
        return "N/A";
    }
  }

  get tagTimeSpan(): any {
    if (!DataQuality.dataAvailabilityConfig) return null;
    const config = DataQuality.dataAvailabilityConfig.configurations.find(item => this.columnName === item.featureName);
    if (!config) return null;
    return {
      observeTimeSpan: this.convertToCorrectTimeUnit(config.warningTimeSeconds)[0],
      observeTimeSpanUnit: this.convertToCorrectTimeUnit(config.warningTimeSeconds)[1],
      criticalTimeSpan: this.convertToCorrectTimeUnit(config.criticalTimeSeconds)[0],
      criticalTimeSpanUnit: this.convertToCorrectTimeUnit(config.criticalTimeSeconds)[1],
    };
  }

  get tagTimeSpanByStatus(): { value: string; unit: string } | null {
    if (this.tagTimeSpan == null) return null;
    if (this.status === "Observe") {
      return { value: this.tagTimeSpan.observeTimeSpan, unit: this.tagTimeSpan.observeTimeSpanUnit };
    } else if (this.status === "Alert") {
      return { value: this.tagTimeSpan.criticalTimeSpan, unit: this.tagTimeSpan.criticalTimeSpanUnit };
    } else return null;
  }

  //  @Methods
  navigateToLink(): void {
    if (this.link === undefined) {
      throw new Error("Link is undefined");
    }
    this.$router.push(this.link);
  }

  convertToCorrectTimeUnit(numberInSeconds: number): [string, string] {
    if (numberInSeconds >= 2592000) {
      return [Math.round(numberInSeconds / 2592000).toString(), "month (s)"];
    }
    if (numberInSeconds >= 86400) {
      return [Math.round(numberInSeconds / 86400).toString(), "day (s)"];
    }
    if (numberInSeconds >= 3600) {
      return [Math.round(numberInSeconds / 3600).toString(), "hour (s)"];
    }

    return [numberInSeconds.toString(), "second (s)"];
  }

  getFormatedDateTimeString(timestamp: string): string {
    return dateHelper.getFormatedDateTimeString(timestamp);
  }

  duration(timestamp: string): string {
    return dateHelper.getHumanizeDuration(timestamp, moment().valueOf());
  }
}
