





































  import { Vue, Component } from "vue-property-decorator";
  import DataQualityIndicator from "@/components/dataQuality/DataQualityIndicator.vue";

@Component({
  components: {
    DataQualityIndicator,
  },
})
  export default class DataQualityIndicatorLegend extends Vue {

  }
