import * as signalR from "@microsoft/signalr";
import store from "@/store";

const isLocal = window.location.hostname === 'localhost';
const baseUrl = isLocal ? process.env.VUE_APP_SIGNALR_BASE_HUB_URL : window.location.origin;

const outageHubConnection = new signalR.HubConnectionBuilder()
  .withUrl(`${baseUrl}/outagehub`, {
    accessTokenFactory: () => store.getters["Auth/getAccessToken"],
  })
  .withAutomaticReconnect()
  .build();

export default outageHubConnection;
